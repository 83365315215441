<template>
  <div class="container justify-content-center d-flex flex-column h-100">

    <div class="flex-33 d-flex align-items-center justify-content-center">
    <CircleComp :text="title"/>
    <div style="margin-bottom: 5cm;"></div> <!-- Ajout d'un espace entre les inputs -->

    </div>

    <div class="flex-33 d-flex flex-column justify-content-center">

        <div class="row justify-content-center">
            <div class="error" >{{error}}</div>
        </div>

        <div class="row justify-content-center md-form md-outline">
          <span>
            <input @change="myChange()" v-model="user.email" value="xxx@free.fr" :placeholder="$t('email')" autofocus />
            <i class="here bi bi-eye white"></i>
          </span>
        </div>
        <div style="margin-bottom: 1rem;"></div> <!-- Ajout d'un espace entre les inputs -->
        <div v-if="!forgotten" class="row justify-content-center md-form md-outline ">
          <span>
            <input @change="myChange()" :type="show_pass ? 'text':'password'" v-model="user.password" value="****" :placeholder="$t('password')" />
            <i @click='show_pass=!show_pass' v-if='show_pass' class="here bi bi-eye-slash"></i>
            <i @click='show_pass=!show_pass' v-if='!show_pass' class="here bi bi-eye"></i>
          </span>
        </div>
        <div v-if="!forgotten" class="row justify-content-center">
            <a href="#" @click="forgotten=true; error=null; title=$t('passwordforgot')" style="text-decoration: none; color: grey;">{{$t('passwordforgot')}}</a>
        </div>

        <div class="flex-33 d-flex flex-column justify-content-center">
        <div class="row justify-content-center mt-5">
           <div class=''><button @click="doConnect()" type="button" class="btn btn-primary buttons text-uppercase">{{$t('validate')}}</button></div>
        </div>
        <div class="row justify-content-center">
           <div class=''><button @click="doReturn()" type="button" class="btn btn-outline-primary text-uppercase">{{$t('return')}}</button></div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import CircleComp from '@/components/CircleComp.vue'

export default {
  data(){
      return {
        user:{},
        forgotten: false,
        title: 'CONNEXION', //this.$i18n.t('login'),
        show_pass: false,
        error : null,
      }
  },
  name: "UserLogin",
  components: {
    CircleComp,
  },
  methods: {
    doConnect(){
      if (this.forgotten) {
        console.log('error', "Email envoyé...");
        MyFlashDS.forgotUser(this.user).then(res=>{
          console.log('error', res.data);
          this.$emit('onAlert', 'info', res.data);
          this.forgotten = false;
          this.error = "un email vous a été envoyé"
          this.title = this.$i18n.t('login')
        })
        return;
      }


      MyFlashDS.loginUser(this.user).then(res=>{
        this.$emit('onAlert', 'success', ('welcome, ')+res.data.pseudo);
        //this.$emit('doReturn', "OK");
        let url = this.$route.query.back+'?connect='+this.$route.query.connect;
        this.$router.push(this.$route.query.back ? url : '/user');
      }).catch(error=>{
        console.log('error', error.message);
        this.error = "Erreur d'authentification";
        this.$emit('onAlert', 'warning', "Erreur d'autentification");
      });
    },
    doReturn(){
      this.$emit('doReturn', "OK");
    },
    myChange() {
    },
  },
  mounted() {
        this.$emit('onAlert', 'danger', 'login');
  }
}
</script>


<style scoped>
.error {
  color: red;
  font-size: 80%;
  text-align: start !important;
  min-height: 3vh;
}

i.here {
  margin-left: -20px;
  cursor: pointer;
}

button {
    cursor: pointer;
    width: 30vh;
    margin-top: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    font-family: ITCAvantGarde, serif;
}

img.logo {
    object-fit: cover;
    height: 40vh;
}

input {border: 1px solid var(--couleur-principale);}

@media (min-width: 1200px) {
  input { width: 50vw;}
  .error { width: 50vw;}
}
@media (max-width: 1200px) {
  input { width: 75vw;}
  .error { width: 75vw;}
}

.white {opacity: 0;}

.flex-33 {
  flex: 1 1 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-100 {
  height: 100vh;
}








  .buttonsX {
    color: black;
    margin-top: 2vh;
    background-color: rgb(0, 133, 197);
    border-radius: 7px;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    cursor: pointer;
    width: 30vh;
    }

/*
  container: {
    height: "90vh";
    display: "flex";
    alignItems: "center";
    textAlign: "center";
    marginBottom: '10vh';
  }
  button: {
    color: "white";
    marginTop: "2vh";
    backgroundColor: "#0085c5";
    borderRadius: "7px";
    paddingTop: "1vh";
    paddingBottom: "1vh";
    fontSize: "1rem";
    cursor: "pointer";
    width: "30vh";
    "@media only screen and (maxWidth: 1024px)": {
      width: "80vw"
    };
    "@media only screen and (minWidth: 1025px)": {
      maxHeight: "80vh";
      width: "100%"
    }
  };
  kezako: {
    color: "#0085c5";
    backgroundColor: "#FFF";
    marginTop: "2vh";
    border: "2px solid #0085c5";
    borderRadius: "7px";
    fontSize: "1rem";
    paddingTop: "1vh";
    paddingBottom: "1vh";
    cursor: "pointer";
    "@media only screen and (maxWidth: 1024px)": {
      width: "80vw"
    };
    "@media only screen and (minWidth: 1025px)": {
      maxHeight: "80vh";
      width: "100%"
    }
  };
  lien: {
    fontSize: "0.8rem";
    textDecoration: "none";
    color: "grey"
  };
  responsive: {
    height: "40vh"
  }
*/

</style>
